<template>
	<div class="contest">
		<!-- Главный блок -->
		<div class="contest-cover">
			<div class="contest-cover-pic">
				<div
					class="image-cover-wrap"
					:style="'/img/contest-cover-4.jpg' | bgrImageCover"
				/>
			</div>
			<div class="contest-cover-content">
				<div class="content">
					<Breadcrumb
						:page-name="'Финал'"
						:additional-pages="[{name: 'Оскар за профессию', link: '/contest/oscar'}]"
					/>
					<div class="grid">
						<div class="box">
							<h3 class="page-title">
								Оскар за профессию <br> итоги конкурса
							</h3>
							<h3 class="block-title">
								Объявлены результаты конкурса
							</h3>
							<div class="buttons">
								<button
									class="button blue"
									@click="$scrollTo($refs.works)"
								>
									Работы победителей
								</button>
								<router-link
									to="/contest/oscar"
									class="button-hollow"
								>
									На страницу конкурса
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section
			ref="works"
			class="section-simple wow"
		>
			<div class="content">
				<div class="section-title-wrap">
					<div class="grid">
						<div class="box">
							<h3 class="block-title">
								Обращение главы оргкомитета конкурса<br>
								Светланы Матвеевой
							</h3>
						</div>
						<div class="box" />
					</div>
				</div>
				<div class="contest-videos">
					<video
						src="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/final_2_video.mp4"
						:poster="'/img/video/final_2_video_preview.png' | prodSrcImage"
						width="100%"
						height="100%"
						preload="none"
						muted
						playsinline
						controls
						controlsList="nodownload"
					/>
				</div>
			</div>
		</section>

		<section
			ref="works"
			class="section-simple wow"
		>
			<div class="content">
				<div class="section-title-wrap">
					<div class="grid">
						<div class="box">
							<h3 class="block-title">
								Работы победителей
							</h3>
						</div>
						<div class="box" />
					</div>
				</div>
				<div class="contest-videos">
					<div
						v-if="!isLoaded"
						class="preloader sm"
						style="margin-top: 100px"
					/>
					<div
						v-else
						class="grid"
					>
						<div
							v-for="item in videos"
							:key="item.id"
							class="box"
						>
							<div class="video">
								<div
									class="video-body"
									@click="modalOpen('video_', { src: item.video_cloud_url || item.video })"
								>
									<div class="video-pic">
										<div class="video-tag">
											{{ item.nominations.name || contest.nominations.find(x => x.id === item.nominations).name }}
										</div>
										<div
											v-if="item.video_preview"
											class="image-cover-wrap"
											:style="item.video_preview | bgrDynamicImageCover"
										/>
									</div>
									<h2 class="video-title">
										{{ item.name }}
									</h2>
								</div>
								<div class="video-person">
									{{ item.team_name }}
								</div>
								<div class="video-company">
									{{ item.company_name }}
								</div>
								<div class="cells">
									<div class="cell">
										<div class="video-votes">
											<img
												v-if="isAvailableVote(item.id)"
												:src="'/img/icons/icon-heart-hollow-bold.svg' | prodSrcImage"
												alt="Нет лайка"
											>
											<img
												v-else
												:src="'/img/icons/icon-heart.svg' | prodSrcImage"
												alt="Есть лайка"
											>
											{{ item.total_likes }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Задать вопрос -->
		<section class="section-simple light">
			<div class="content">
				<h3 class="block-title">
					Задать вопрос
				</h3>
				<div class="contacts">
					<div class="contact">
						Все вопросы можно уточнить, связавшись с нами по почте: <a
							href="mailto:info@mail.lift-bf.ru"
							class="link-dashed"
						>info@mail.lift-bf.ru</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import Cnt from '@/api/contest'
import Breadcrumb from '@/components/Breadcrumb';

export default {
    components: {
        Breadcrumb
    },
    data: () => ({
        liked: new Array(),
        videos: new Array()
    }),
    computed: {
        userID() {
            return this.$store.getters['user/userID']
        },
        contest() {
            return this.$store.state.cnt.contest
        },
        isLoaded() {
            return this.videos && this.contest
        }
    },
    created() {
        !this.contest && this.$store.dispatch('cnt/getAllContests')

        Cnt.getAllFans()
            .then(response => this.liked = response.data.filter(x => x.likes.some(y => y.id === this.userID)).map(x => x.id))

        Promise.all([
            !IS_TEST && !IS_DEV && Cnt.getVideo(150),
            !IS_TEST && !IS_DEV && Cnt.getVideo(123),
            !IS_TEST && !IS_DEV && Cnt.getVideo(56),
            !IS_TEST && !IS_DEV && Cnt.getVideo(58),
            !IS_TEST && !IS_DEV && Cnt.getVideo(110),
            !IS_TEST && !IS_DEV && Cnt.getVideo(59),
            /////////////////////////
            (IS_TEST || IS_DEV) && Cnt.getVideo(9),
            (IS_TEST || IS_DEV) && Cnt.getVideo(19),
            (IS_TEST || IS_DEV) && Cnt.getVideo(21)
        ]).then(response => response.filter(x => !!x).forEach(x => this.videos.push(x.data)))
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        isAvailableVote(id) {
            if (this.liked.includes(id)) {
                return false
            } else {
                return true
            }
        }
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/contest/index";
</style>

<style scoped lang="sass">
.block-pretitle
  margin-bottom: 5px
  font-size: 15px
  font-weight: 400
  text-transform: uppercase
  letter-spacing: 0.04em

.contest-cover .button-hollow
  min-width: 200px
  background: none
  border-color: #fff
  color: #fff
  &:hover
    background: #EF722E
    border-color: #EF722E
    color: #fff


.contest-videos
    &.top-10 .box
        .video
            &-pic
                border: none
            &:after
                display: none
    .video-pic
        cursor: pointer
</style>
